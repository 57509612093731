/* You can add global styles to this file, and also import other style files */

@import 'assets/bootstrap/bootstrap-grid.min.css';
@import 'assets/icons/style.css';
@import 'variables';
@import 'inter';
@import 'global';
@import 'icons';
@import 'buttons';
@import 'dialogs';
@import 'table';
@import 'tabs';

:root {
  font-family: $font-family;
  --font-family: $font-family;
  font-feature-settings: $font-features;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: 'InterVariable', sans-serif;
    font-optical-sizing: auto;
    --font-family: 'InterVariable', sans-serif;
    font-optical-sizing: auto;
  }
}
