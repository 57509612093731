@import 'variables';

html {
  height: 100%;
  min-width: 1900px;
  overflow-y: scroll;
}

body {
  color: black;
  font-family: $font-family;
  font-size: $font-size-base;
  font-weight: 400;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.page-padding {
  padding: 22px;
}

.text-left {
  text-align: start !important;
}

.text-right {
  text-align: end !important;
}

.text-center {
  text-align: center !important;
}

i[class^='icon-'],
i[class*=' icon-'],
span[class^='icon-'],
span[class*=' icon-'] {
  line-height: $font-size-base;
  display: inline-block;

  &::before {
    vertical-align: middle;
  }
}

.p-datatable-scrollable-header-box {
  padding-right: 0 !important;
}

// Cursor classes - self declared please use at the according areas
.cursor-clickable {
  cursor: pointer;
}
.cursor-under-construction {
  cursor: no-drop;
}
.cursor-help {
  cursor: help;
}
